import axios from 'axios';
import ICONS from 'config/icons';
import { requestAuthenticated } from 'utils';
import STRINGS from 'config/localizedStrings';
import { playBackgroundAudioNotification } from 'utils/utils';
import { ERC20ReadContract, ERC20WriteContract, getSubscribeMode, pairReadContract, pairWriteContract } from 'utils/wagmiWrapper';
import { price2Point, toPlainBalance } from 'utils/bitlyBignumber';
import { message } from 'antd';
import { API_URL } from 'config/constants';

// Set orders from websocket
export function setUserOrders(orders) {
	return {
		type: 'SET_USER_ORDERS',
		payload: orders,
	};
}

export function addOrder(order) {
	return {
		type: 'ADD_ORDER',
		payload: { order },
	};
}

export function updateOrder(order) {
	return {
		type: 'UPDATE_ORDER',
		payload: { order },
	};
}

export function removeOrder(ids = {}) {
	return {
		type: 'REMOVE_ORDER',
		payload: { ids: [ids.id] },
	};
}

export const submitOrder = async (order) => {
    const pairInfo  = await pairReadContract('pairInfo', undefined, order.symbol);
    const originToken = order.side == 'buy' ? pairInfo[1] : pairInfo[0];
    const targetToken = order.side == 'buy' ? pairInfo[0] : pairInfo[1];
    const decimal  = await ERC20ReadContract('decimals', undefined, originToken);
    order.estimatedPrice = order.type == 'limit' ? order.size * order.price : order.estimatedPrice;
    const orderSize = order.side == 'sell' ? order.size: order.estimatedPrice;

    let ret;
    if (order.type == 'limit') {
        ret = await pairWriteContract('limitOrder', [
            originToken,
            price2Point(order.price).toNumber(),
            toPlainBalance(orderSize, decimal).toString(),
            order.symbol,
            order.symbol
        ], order.symbol);
    } else {
        if (orderSize <= 1e-5) {
            ret = {
                ok: false,
                res: 'Up to Market, Please try to load more orders'
            };
            return ret;
        }
        const curPoint = await pairReadContract("curPoint", undefined, order.symbol);
        ret = await pairWriteContract('marketOrder', [
            originToken,
            toPlainBalance(orderSize, decimal).toString(),
            curPoint,
            order.slippage,
            order.symbol,
            order.symbol
        ], order.symbol);
    }
    fetch(API_URL+`/api/update?pair=${order.symbol}`);

    return ret;
};

export const cancelOrder = async (orderId, settings) => {
    const [pair, originToken, targetToken, point] = orderId.split("-");
	return pairWriteContract("cancelLimitOrder", [originToken, point], pair);

};

export const claim = async (orderId, settings) => {
    const [pair, originToken, targetToken, point] = orderId.split("-");
	return pairWriteContract("claimEarning", [originToken, point], pair);
};

export const cancelAllOrders = async (symbol = '', settings) => {
    return pairWriteContract("cancelAllLimitOrders", undefined, symbol);
};

export const claimAll = async (symbol = '', settings) => {
    return pairWriteContract("claimAllEarnings", undefined, symbol);
};

export const executeBroker = (values) => {
	const options = {
		method: 'POST',
		body: JSON.stringify(values),
	};

	return requestAuthenticated('/broker/execute', options);
};
